.header {
    border-bottom: 1px solid rgb(232, 232, 232);
    display: flex;
    justify-content: center;
    height: 84px;
    width: 100%;

    &__container {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: inherit;
        padding: 0 30px;
        width: clamp(300px, 100%, 1200px);

        &__logo {
            height: 50px;
            width: 50px;
        }
    
        &__navigation {
            align-items: center;
            display: flex;
            flex-direction: row;
            margin-left: 30px;

            > p {
                align-items: center;
                color: white;
                cursor: pointer;
                display: flex;
                font-size: 15px;
                font-weight: 600;
                margin-right: 20px;

                &:hover {
                    color: rgb(229, 229, 229);
                }
            }

            &__add-button {
                display: initial;

                @media screen and (max-width: 400px) {
                    display: none;
                }
            }
        }

        &__menu {
            align-items: end;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            height: 12px;
            justify-content: space-between;

            span:first-child {
                background-color: white;
                height: 2px;
                width: 25px;
            }

            span:last-child {
                background-color: white;
                height: 2px;
                width: 20px;
            }
        }

    }

}

.header_dark_text {
    color: #1a3760 !important;
}