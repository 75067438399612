.user-navigation__mobile {
    
    &__dropdown {
        align-items: center;
        border: none;
        background-color: #1967d2;
        border-radius: 8px;
        color:white;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        height: 70px;
        padding: 0 30px;
        width: 100%;

        > span {
            margin-right: 15px;
        }
    }

    &__list {
        background-color: #1967d2;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        padding: 10px 0;

        > button {
            align-items: center;
            background-color: #1967d2;
            border: 1px solid #1967d2;
            color:white;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: start;
            height: 50px;
            padding: 0 32px;
            transition: 200ms;

            > i {
                font-size: 18px;
                margin-right: 17px;
            }

            &:hover {
                color: #6cbf78;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        display: none;
    }
}

.user-navigation__desktop {
    border-right: 1px solid rgb(232, 232, 232);
    display: flex;
    flex-direction: column;
    padding: 60px 20px 0 20px;
    width: 260px;

    > button {
        align-items: center;
        background-color: white;
        border: 1px solid white;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        font-size: 13px;
        font-weight: 300;
        justify-content: start;
        height: 50px;
        margin-bottom: 2px;
        padding: 0 20px;
        transition: 300ms;

        &:hover {
            background-color: #e6aa1b;
            border: 1px solid #e6aa1b;
        }

        > i {
            margin-right: 8px;
            padding-top: 2px;
        }
    }

    @media screen and (max-width: 1200px) {
        display: none;
    }
}

.selected-item {
    background-color: #e6aa1b !important;
}