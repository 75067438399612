.signup-form {
    width: clamp(260px, 100%, 600px);

    &__row {
        display: inline-flex;
        gap: 10px;
        width: 100%;

        &__cell {
            width: 100%;
        }

        @media screen and (max-width: 600px) {
            display: flex;
            flex-direction: column;
        }

    }

    p {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    &__options {
        margin-top: 18px;
        margin-bottom: 30px;
        width: 100%;

        > div {
            align-items: center;
            display: flex;
            flex-direction: row;

            > label {
                font-size: 13px;
            }
        }
    }
}