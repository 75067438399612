.user-board {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 85px);
    margin: 0 20px;
    width: clamp(300px, calc(100% - 40px), 1600px);

    &__container {
        display: flex;
        flex-direction: column;
        padding: 60px 30px 0 30px;
        width: calc(100% - 60px);
    }

    @media screen and (min-width: 1200px) {
        flex-direction: row;
    }
}