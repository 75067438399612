.dropdown {
    display: flex;
    flex-direction: column;
    margin: 0 15px;
    flex: 1;

    > p {
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
    }

    &__select {
        background-color: #ffffff;
        border: 1px solid #eaeaea;
        border-radius: 8px;
        color: #5f6973;
        font-size: 13px;
        height: 50px;
        padding: 0 15px;
    
        &:focus {
            border: 1px solid #f5c34b;
        }
    
        &:focus-visible {
            border: 1px solid #f5c34b;
            outline: none;
        }
    }
}