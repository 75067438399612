.login-form {
    width: clamp(260px, 100%, 500px);

    &__email {
        margin-bottom: 20px;
    }

    &__password {
        margin-bottom: 20px;
    }

    p {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    &__options {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 25px;
        width: 100%;

        > a {
            font-size: 13px;

            @media screen and (max-width: 360px) {
                margin-top: 20px;
            }
        }

        @media screen and (max-width: 360px) {
            align-items: unset;
            display: flex;
            flex-direction: column;
        }
    }
}