.connect-social {
    border-left: 1px solid rgb(232, 232, 232);
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 130px;
    width: clamp(200px, 100%, 600px);

    &__container {
        display: flex;
        flex-direction: column;

        > h2 {
            margin-top: 0;
        }

        &__fb {
            background-color: #ffffff;
            border: 1px solid #1967d2;
            border-radius: 8px;
            color: #1967d2;
            cursor: pointer;
            font-size: 13px;
            font-weight: 600;
            height: 50px;
            width: 240px;
    
            &:hover {
                background-color: #1967d2;
                color: white;
            }
        }
    
        &__g {
            background-color: #ffffff;
            border: 1px solid #d93025;
            border-radius: 8px;
            color:  #d93025;
            cursor: pointer;
            font-size: 13px;
            font-weight: 600;
            height: 50px;
            margin-top: 20px;
            width: 240px;
    
            &:hover {
                background-color: #d93025;
                color: white;
            }
        }
    }

    @media screen and (max-width: 800px) {
        border-left: unset;
        margin-top: 30px;
    }
}