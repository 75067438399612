.signup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    &__container {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: clamp(300px, 100%, 1200px);

        &__content {
            margin-top: 100px;
            padding: 0 20px;
            width: clamp(300px, 100%, 600px);
    
            > h2 {
                margin-bottom: 30px;
            }
    
            > p {
                font-size: 13px;
                margin-bottom: 20px;
            }
    
            p > a {
                color: inherit;
                font-weight: 500;
                text-decoration: none;
            }
    
            @media screen and (max-width: 600px) {
                margin-top: 30px;
            }
        }

        @media screen and (max-width: 800px) {
            flex-direction: column;
            padding: 0 20px;
        }
    }
}