.home-search {
    width: clamp(300px, 100%, 1000px);
    // max-width: 1000px;

    &__tabs {
        display: flex;
        flex-direction: row;
        justify-content: center;

        &__tab {
            align-items: center;
            background-color: #f5c34b;
            border-radius: 8px 8px 0 0;
            cursor: pointer;
            display: flex;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            height: 40px;
            justify-content: center;
            margin: 0 5px;
            width: 150px;
        }
    }

    &__container {
        align-items: center;
        background-color: white;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: 25px 35px;

        &__drops {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 20px;
            width: 100%;

            @media screen and (max-width: 600px) {
                flex-direction: column;
            }
        }
    }
}

.home-search-selected-tab {
    background-color: white;
}