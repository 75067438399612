.input {
    background-color: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    color: #5f6973;
    font-size: 13px;
    height: 50px;
    padding: 0 15px;
    width: calc(100% - 30px);

    &:focus {
        outline: unset;
    }
}