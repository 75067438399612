.reset-password {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    &__container {
        display: flex;
        flex-direction: column;
        margin-top: 100px;
        padding: 0 20px;
        width: clamp(300px, 100%, 1200px);

        > form {
            display: flex;
            flex-direction: column;

            > input {
                margin-bottom: 30px;
                min-width: 300px;
                max-width: 500px;
            }

            > .log-message {
                min-width: 300px;
                max-width: 500px;
            }
        }
    }
}