.button {
    border-radius: 8px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    height: 50px;
}

.color-yellow {
    background-color: #f5c34b;
    border: 1px solid #f5c34b;

    &:hover {
        background-color: #e6aa1b;
        border: 1px solid #e6aa1b;
    }

    &:active {
        background-color: #ffffff;
        border: 1px solid #d0d0d0;
    }
}