.log-message {
    align-items: center;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    font-size: 13px;
    height: 30px;
    margin: 20px 0;
    padding: 0 20px;
    width: calc(100% - 40px);

    > span {
        font-size: 15px;
        margin-right: 5px;
    }
}

.log-message-success {
    border: 1px solid #78f54b;
    background-color: #78f54b;
}

.log-message-warning {
    border: 1px solid #f5c34b;
    background-color: #f5c34b;
}

.log-message-error {
    border: 1px solid #f54b6d;
    background-color: #f54b6d;
}