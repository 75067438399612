.sidemenu {
    background-color: white;
    border-left: 1px solid rgb(232, 232, 232);
    height: calc(100vh - 40px);
    padding: 20px 30px;
    position: absolute;
    right: 0;
    top: 0;
    width: 300px;
    z-index: 9999;

    &__header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgb(232, 232, 232);
        padding-bottom: 13px;

        &__user-info {
            align-items: center;
            display: flex;
            flex-direction: row;

            &__img {
                align-items: center;
                background-color: rgb(186, 186, 186);
                border-radius: 23px;
                display: flex;
                justify-content: center;
                height: 46px;
                margin-right: 10px;
                width: 46px;
            }

            > p {
                font-size: 15px;
                font-weight: 500;
                max-width: 180px;
                text-wrap: wrap;
            }
        }

        button {
            background-color: #f5c34b;
            border: 1px solid #f5c34b;
            border-radius: 23px;
            cursor: pointer;
            font-size: 13px;
            font-weight: 600;
            height: 46px;
            width: 46px;

            &:hover {
                background-color: #e6aa1b;
                border: 1px solid #e6aa1b;
            }
        }
    }

    &__menu-list {
        list-style-type: none;
        padding: unset;

        &__list {
            align-items: center;
            border: 1px solid white;
            border-radius: 8px;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            font-size: 15px;
            padding: 13px 15px;
            transition: 300ms;

            > i {
                height: 15px;
                margin-right: 8px;
            }

            &:hover {
                background-color: #e6aa1b;
                border: 1px solid #e6aa1b;
            }
        }
    }
}