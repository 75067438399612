.dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;

    > h2 {
        margin: 10px 0;
    }

    > p {
        margin: 0;
    }

    &__infos {
        display: inline-flex;
        gap: 10px;
        margin-top: 50px;
        width: 100%;

        &__content {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            min-width: 200px;

            &__list-icon {
                align-items: center;
                background-color: rgba(25, 72, 226, 0.07);
                border-radius: 35px;
                display: flex;
                justify-content: center;
                height: 70px;
                width: 70px;

                > span {
                    color: #1947e2;
                    font-size: 40px;
                }
            }

            &__list-text {
                text-align: right;

                > h2 {
                    color: #1947e2;
                    margin: 10px 0;
                }

                > p {
                    margin: 10px 0;
                }
            }

            &__messages-icon {
                align-items: center;
                background-color: rgba(245, 195, 75, 0.15);
                border-radius: 35px;
                display: flex;
                justify-content: center;
                height: 70px;
                width: 70px;

                > span {
                    color: #f5c34b;
                    font-size: 40px;
                }
            }

            &__messages-text {
                text-align: right;

                > h2 {
                    color: #f5c34b;
                    margin: 10px 0;
                }

                > p {
                    margin: 10px 0;
                }
            }

            &__fav-icon {
                align-items: center;
                background-color: rgba(245, 75, 75, 0.15);
                border-radius: 35px;
                display: flex;
                justify-content: center;
                height: 70px;
                width: 70px;

                > span {
                    color: #f54b4b;
                    font-size: 40px;
                }
            }

            &__fav-text {
                text-align: right;

                > h2 {
                    color: #f54b4b;
                    margin: 10px 0;
                }

                > p {
                    margin: 10px 0;
                }
            }
        }

        @media screen and (max-width: 900px) {
            display: flex;
            flex-direction: column;
        }

        > .card {
            @media screen and (max-width: 900px) {
                width: unset;
            }
        }
    }
}