.home {
    background-image: url(../../assets/home/1.jpg);
    background-size: cover;
    background-position: center center;
    height: 800px;
    margin-top: -85px;

    &__search {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 30px;
        position: relative;
        top: 200px;

        > h1 {
            font-size: 3em;
        }
    }
}